/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/jquery@3.6.0/dist/jquery.slim.min.js
 * - /npm/jquery@3.6.0/dist/jquery.slim.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
